<template>
  <div class="box">
    <el-tabs v-model="activeName" @tab-click="clickTab">
      <el-tab-pane label="销售渠道类别" name="first">
        <div class="channel-type">
          <div>
            <el-button type="primary" @click="addChannelType">添加</el-button>
          </div>
          <div class="channel-list">
            <el-table :data="channelTypeList" stripe style="width: 100%">
              <el-table-column prop="name" label="渠道"></el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button
                      @click="addChannelModel(scope.row)"
                      type="text"
                      size="small"
                  >新增渠道
                  </el-button
                  >
                  <el-button
                      @click="ChannelAddTicket(scope.row)"
                      type="text"
                      size="small"
                  >新增票种
                  </el-button
                  >
                  <el-button
                      @click="changeQudaoType(scope.row)"
                      type="text"
                      size="small"
                  >修改
                  </el-button
                  >
                  <el-button
                      @click="delQudaoType(scope.row)"
                      type="text"
                      size="small"
                  >删除
                  </el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="渠道" name="second">
        <div class="channel-list">
          <el-card>
            <el-row :gutter="20">
              <el-col :span="5">
                <el-select
                    v-model="avtiveQudaoType"
                    clearable
                    placeholder="查看渠道类别下的渠道"
                    @change="viewQudaoType"
                >
                  <el-option
                      v-for="(item, index) in channelTypeList"
                      :key="index"
                      :label="item.name"
                      :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-card>

          <el-table :data="channelList" stripe style="width: 100%">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="渠道名称"></el-table-column>
            <el-table-column prop="typeName" label="渠道类型">
            </el-table-column>
            <el-table-column prop="money" label="金额"></el-table-column>
            <el-table-column prop="tel" label="手机号码"></el-table-column>
            <el-table-column prop="bankNumber" label="银行卡号">
            </el-table-column>
            <el-table-column prop="aLiNumber" label="支付宝账号">
            </el-table-column>
            <el-table-column prop="aLiName" label="支付宝实名">
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                    @click="changeChannelModel(scope.row)"
                    type="text"
                    size="small"
                >修改
                </el-button
                >
                <el-button
                    @click="delchannel(scope.row)"
                    type="text"
                    size="small"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>

      <el-tab-pane label="票种" name="third">
        <el-card>
          <el-row :gutter="20">
            <el-col :span="5">
              <el-select
                  v-model="avtiveQudao"
                  clearable
                  placeholder="查看渠道票种"
                  @change="viewQudaoTicket"
              >
                <el-option
                    v-for="(item, index) in channelTypeListOld"
                    :key="index"
                    :label="item.name"
                    :value="item.ID"
                >
                </el-option>
              </el-select>
            </el-col>

            <el-col :span="5">
              <el-select
                  v-model="avtiveQuyu"
                  clearable
                  placeholder="查看区域票种"
                  @change="viewQuyuTicket"
              >
                <el-option
                    v-for="(item, index) in ticketAreaList"
                    :key="index"
                    :label="item.areaName"
                    :value="item.areaName"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-card>

        <div class="channel-list">
          <el-table :data="ticketList" stripe style="width: 100%">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="sysCode" label="票种编号"></el-table-column>
            <el-table-column label="渠道">
              <template slot-scope="scope">
                {{ scope.row.qudaoID | foramtQudao }}
              </template>
            </el-table-column>
            <el-table-column prop="areaName" label="区域"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="type" label="类型"></el-table-column>
            <el-table-column prop="price" label="价格"></el-table-column>
            <el-table-column prop="time" label="有效期" width="200">
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <div>开启</div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                    @click="changeTicketType(scope.row)"
                    type="text"
                    size="small"
                >修改
                </el-button
                >
                <el-button
                    @click="deticketType(scope.row)"
                    type="text"
                    size="small"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :title="`${operationQudao}渠道`" :visible.sync="isCreateQudao">
      <div class="add-channel">
        <el-form
            :model="qudaoForm"
            :rules="qudaoRules"
            ref="qudaoForm"
            label-width="auto"
            class="demo-qudaoForm"
        >
          <el-form-item
              v-if="operationQudao === '新增'"
              label="渠道类型"
              prop="type"
          >
            <el-select
                disabled
                v-model="qudaoForm.typeName"
                placeholder="请选择渠道类型"
            >
              <el-option
                  v-for="(item, index) in channelTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-else label="渠道类型" prop="type">
            <el-select
                v-model="qudaoForm.typeID"
                placeholder="请选择渠道类型"
                @change="changeQudaoOnType"
            >
              <el-option
                  v-for="(item, index) in channelTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.ID"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="渠道名称" prop="name">
            <el-input
                v-model="qudaoForm.name"
                placeholder="请输入渠道名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人或名称" prop="contactName">
            <el-input
                v-model="qudaoForm.contactName"
                placeholder="请输入联系人或名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="手机号码" prop="tel">
            <el-input
                v-model="qudaoForm.tel"
                placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="operationQudao === '新增'" label="登录账号" prop="account">
            <el-input
                v-model="qudaoForm.account"
                placeholder="请输入登录账号"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="operationQudao === '新增'" label="登录密码" prop="password">
            <el-input :type="typePwd" v-model="qudaoForm.password">
              <el-button
                  slot="append"
                  icon="el-icon-view"
                  circle
                  @click="showPwd">
              </el-button>
            </el-input>
          </el-form-item>

          <el-form-item label="分销方式">
            <el-select
                v-model="qudaoForm.fenxiao_type"
                placeholder="请选择分销方式"
            >
              <el-option label="金额百分比" value="percent"></el-option>
              <el-option label="按票数分销" value="count">按票数分销</el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="分销基数">
            <el-input-number v-model="qudaoForm.fenxiao_base" :precision="2" :step="0.1" :min="0"
                             placeholder="请输入系统编号"></el-input-number>
            <p style="font-size: 12px;color: red">类型为按金额百分比时,最终金额等于分销基数*订单价格;</p>
            <p style="font-size: 12px;color: red">类型为按票数分销时,最终金额等于分销基数*订单数量</p>
          </el-form-item>

          <el-form-item label="系统编号" prop="sysCode">
            <el-input
                v-model="qudaoForm.sysCode"
                placeholder="请输入系统编号"
            ></el-input>
          </el-form-item>

          <el-form-item label="银行卡号" prop="bankNumber">
            <el-input
                v-model="qudaoForm.bankNumber"
                placeholder="请输入银行卡号"
            ></el-input>
          </el-form-item>

          <el-form-item label="支付宝账号" prop="aLiNumber">
            <el-input
                v-model="qudaoForm.aLiNumber"
                placeholder="请输入支付宝账号"
            ></el-input>
          </el-form-item>

          <el-form-item label="支付宝实名" prop="aLiName">
            <el-input
                v-model="qudaoForm.aLiName"
                placeholder="请输入支付宝实名"
            ></el-input>
          </el-form-item>
          <el-form-item label="可售票种" prop="ticket_list">
            <el-checkbox-group v-model="qudaoForm.ticket_list" >
              <el-checkbox v-for="item in ticketList" :label="item['ID']" :key="item.ID">{{item['areaName']}}-{{item['name']}}-{{item['price']}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addChannel('qudaoForm')">{{
                operationQudao
              }}
            </el-button>
            <el-button v-if="operationQudao=='新增'" @click="resetForm('qudaoForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :title="operationTicket+'票种'" :visible.sync="isCreateTicket">
      <el-form
          :model="ticketForm"
          :rules="ticketRules"
          ref="ticketForm"
          label-width="auto"
          class="demo-qudaoForm"
      >
        <el-form-item label="渠道" prop="qudaoName">
          <el-input v-model="ticketForm.qudaoName" disabled></el-input>
        </el-form-item>

        <el-form-item label="票种名称" prop="name">
          <el-input
              v-model="ticketForm.name"
              placeholder="请输入票种名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="票种类型" prop="type">
          <el-input
              v-model="ticketForm.type"
              placeholder="请输入票种类型"
          ></el-input>
        </el-form-item>

        <el-form-item label="可选座位数量" prop="seat_number">
          <el-input-number
              v-model="ticketForm.seat_number"
              step-strictly
              :min="1"
              :max="999"
              label="座位数量"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="销售价格" prop="price">
          <el-input
              v-model="ticketForm.price"
              placeholder="请输入销售价格"
          ></el-input>
        </el-form-item>

        <el-form-item label="开始日期-结束日期" prop="times">
          <el-date-picker
              v-model="ticketForm.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="confirmTime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="区域" prop="areaName">
          <el-select v-model="ticketForm.areaName" placeholder="请选择区域">
            <el-option
                v-for="(item, index) in ticketAreaList"
                :key="index"
                :label="item.areaName"
                :value="item.areaName"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="系统编号" prop="sysCode">
          <el-input
              v-model="ticketForm.sysCode"
              placeholder="请输入系统编号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="addTicket('ticketForm')">{{
              operationTicket
            }}
          </el-button>
          <el-button @click="resetForm('ticketForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>


<script>
import {
  qudaoCreateQudaotype,
  qudaoTypeList,
  qudaoEditType,
  qudaoDelType,
  createQudao,
  qudaoList,
  editQudao,
  delQudao,
  createTicket,
  ticketList,
  editTicket,
  delTicket,
  getTicketArea,
} from "@/network/channel.js";

import {checkPhoneNum, checkCardNum} from "@/utils/validateForm.js";

let that = this;
export default {
  name: "channel",
  data() {
    return {
      typePwd: "password",

      // 当前渠道
      avtiveQudao: "",
      // 当前区域
      avtiveQuyu: "",
      //tab选项卡
      activeName: "first",
      // 渠道类型数据
      channelTypeList: [],
      // 渠道类型数据
      channelTypeListOld: [],
      // 渠道列表
      channelList: [],
      // 票种列表
      ticketList: [],
      // 票种列表原数据
      ticketListOld: [],
      // 新增票种弹窗
      isCreateTicket: false,
      // 新增渠道弹窗
      isCreateQudao: false,
      //渠道弹窗
      operationQudao: "新增",
      // 渠道类型
      qudaoName: "",
      //票种弹窗
      operationTicket: "添加",
      avtiveQudaoType: '',
      // 票区
      ticketAreaList: [
        {
          areaName: "A区",
        },
        {
          areaName: "B区",
        },
        {
          areaName: "C区",
        },
        {
          areaName: "VIP区",
        },
      ],

      //表单数据
      qudaoForm: {
        type: "",
        name: "",
        contactName: "",
        sysCode: "",
        tel: "",
        bankNumber: "",
        aLiNumber: "",
        aLiName: "",
        account: '',
        password: '',
        fenxiao_type: 'percent',
        fenxiao_base: 0,
        ticket_list:[]
      },
      // 表单规则
      qudaoRules: {
        typeName: [
          {required: true, message: "请选择渠道类型", trigger: "change"},
        ],
        name: [{required: true, message: "请输入渠道名称", trigger: "blur"}],
        account: [{required: true, message: "请输入渠道登录账号", trigger: "blur"}],
        password: [{required: true, message: "请输入渠道登录密码", trigger: "blur"}, {
          min: 6,
          message: '长度最少6个字符',
          trigger: 'blur'
        }],
        contactName: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: ["change", "blur"],
          },
        ],
        // sysCode: [
        //   {  message: '请输入系统编号', trigger: ['change', 'blur'] }
        // ],
        tel: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: ["change", "blur"],
          },
          // { validator: checkPhoneNum, trigger: 'blur' },
        ],
        // bankNumber: [
        //   {  message: '请输入银行卡号', trigger: ['change', 'blur'] },
        //   { validator: checkCardNum, trigger: 'blur' },

        // ],
        // aLiNumber: [
        //   {  message: '请输入支付宝账号', trigger: ['change', 'blur'] }
        // ],
        // aLiName: [
        //   {  message: '请输入支付宝实名', trigger: ['change', 'blur'] }
        // ],
      },
      ticketForm: {
        qudaoName: "",
        type: "单票",
        name: "成人票",
        qudaoID: "",
        price: "",
        sysCode: "",
        time: "",
        endTime: "",
        startTime: "",
        areaName: "",
        seat_number: 1,
      },
      ticketRules: {
        qudaoName: [
          {required: true, message: "请选择渠道类型", trigger: "change"},
        ],
        name: [
          {
            required: true,
            message: "请输入票种名称",
            trigger: ["change", "blur"],
          },
        ],
        type: [
          {
            required: true,
            message: "请输入票种类型",
            trigger: ["change", "blur"],
          },
        ],
        seat_number: [
          {
            required: true,
            message: "请输入座位数量",
            trigger: ["change", "blur"],
          },
        ],
        price: [
          {
            required: true,
            message: "请输入销售价格",
            trigger: ["change", "blur"],
          },
        ],
        sysCode: [{message: "请输入系统编号", trigger: ["change", "blur"]}],
        time: [
          {
            required: true,
            message: "请输入开始日期-结束日期",
            trigger: ["change", "blur"],
          },
        ],
        areaName: [
          {
            required: true,
            message: "请输入区域",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  async created() {
    that = this;
    // 渠道类别
    this.getChnnelTypeData();
    // 票种
    this.getTickList();
    // 渠道
    this.getChannelList();
  },
  methods: {
    showPwd() {
      this.typePwd = this.typePwd === "password" ? "text" : "password";
    },
    changeQudaoOnType(e){
      console.log(e)
      this.qudaoForm.typeName=this.channelTypeList.find(v=>{return v.ID===e}).name
      this.qudaoForm.ticket_list=[]
      this.viewQudaoTicket(e)
    },
    viewQudaoType(tag) {
      console.log("tag: ", tag);
      this.channelList = this.channelListNew
      if (!tag) {
        this.channelList = this.channelListNew
      } else {
        this.channelList = this.channelList.filter((item) => {
          return item.typeName == tag;
        });
      }
      console.log("channelList: ", this.channelList);
    },
    // 查看渠道票种
    viewQudaoTicket(tag) {
      this.ticketList = JSON.parse(JSON.stringify(this.ticketListOld.filter(v=>!!v.qudaoID)))
      if (!tag) return;
      if (this.avtiveQuyu) {
        this.allFilters();
      } else {
        let qudao = this.channelTypeListOld.filter((item) => {
          return item.ID == tag;
        });
        this.ticketList = this.ticketList.filter((item) => {
          return item.qudaoID == qudao[0].ID;
        });
      }

      console.log("ticketList: ", this.ticketList);
    },
    // 查看区域票种
    viewQuyuTicket(tag) {
      this.ticketList = this.ticketListOld.filter(v=>!!v.qudaoID);
      if (!tag) return;
      if (this.avtiveQudao) {
        this.allFilters();
      } else {
        this.ticketList = this.ticketList.filter((item) => {
          return item.areaName == tag;
        });
      }
      console.log("ticketList: ", this.ticketList);
    },
    // 二者都筛选
    allFilters() {
      let that = this;
      let qudao = this.channelTypeListOld.filter((item) => {
        return item.ID == that.avtiveQudao;
      });
      console.log(that.avtiveQuyu)
      this.ticketList = this.ticketList.filter((item) => {
        if (item.qudaoID == qudao[0].ID && item.areaName == that.avtiveQuyu) {
          return true;
        }
      });
    },

    // 点击导航
    clickTab(tab) {
      console.log(tab.name);
    },

    // 重置表单
    resetForm(formName) {
      console.log("formName: ", formName);
      try {
        this.$refs[formName].resetFields();
      } catch (e) {

      }
    },

    // 获取渠道
    async getChannelList() {
      this.channelList = [];
      let res = await qudaoList();
      console.log("res: ", res);
      if (res.data && res.data.length > 0) {
        this.channelList = res.data;
        this.channelListNew = this.channelList
      }
    },

    // 添加渠道按钮
    addChannel(formName) {
      let that = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log("that.qudaoForm: ", that.qudaoForm);
          let res = "";
          if (that.operationQudao == "修改") {
            res = await editQudao(that.qudaoForm);
            console.log("createQudao--res: ", res);
          } else {
            res = await createQudao(that.qudaoForm);
          }
          if (res.data && res.msg == "success") {
            that.$message({
              type: "success",
              message: `渠道${that.operationQudao}成功`,
            });
            that.isCreateQudao = false;
          } else {
            that.$message({
              type: "error",
              message: res.msg,
            });
            return
          }
          that.resetForm(formName);
          // 更新信息
          that.getChannelList();
        }
      });
    },

    // 点击新增渠道
    addChannelModel(row) {
      this.operationQudao = "新增";
      this.isCreateQudao = true;
      console.log("新增.name: ", row);
      this.viewQudaoTicket(row.ID)

      this.qudaoForm = {
        type: "",
        name: "",
        contactName: "",
        sysCode: "",
        tel: "",
        bankNumber: "",
        aLiNumber: "",
        aLiName: "",
        account: '',
        password: '',
        fenxiao_type: 'percent',
        fenxiao_base: 0,
        ticket_list: this.ticketList.map(v=>v.ID)
      }
      this.qudaoForm.typeName = row.name;
      this.qudaoForm.typeID = row.ID;
    },
    // 点击修改渠道
    changeChannelModel(row) {
      this.viewQudaoTicket(row.typeID)
      let qudaoForm= JSON.parse(JSON.stringify(row))
      qudaoForm.ticket_list=this.ticketListOld.filter(v=>{return v._qudaoID===row.ID}).map(v=>v.UnAuditName||v.ID)
      this.qudaoForm = qudaoForm
      this.operationQudao = "修改";
      this.isCreateQudao = true;
    },
    // 删除渠道
    async delchannel(row) {
      console.log("row: ", row);
      this.$confirm("此操作将永久删除渠道, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            let res = await delQudao({typeId: row.ID});
            console.log("res: ", res);
            if (res.code === 200 && res.msg == "success") {
              this.$message({
                type: "success",
                message: `渠道删除成功`,
              });
              // 更新信息
              this.getChannelList();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    // 获取渠道类别
    async getChnnelTypeData() {
      this.channelTypeList = [];
      let qudaoType = await qudaoTypeList();
      console.log("qudaoType: ", qudaoType.data);
      if (qudaoType.data && qudaoType.data.length > 0) {
        this.channelTypeListOld = qudaoType.data;
        this.channelTypeList = qudaoType.data.map((item) => {
          return {
            name: item.name,
            ID: item.ID,
          };
        });
      }
    },
    // 添加渠道类别
    addChannelType() {
      this.showPrompt("添加");
    },
    // 修改渠道类别
    changeQudaoType(row) {
      console.log(row);
      this.showPrompt("修改", row);
    },
    // 删除渠道类别
    async delQudaoType(row) {
      console.log(row);
      this.$confirm("此操作将永久删除该渠道类别, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            let res = await qudaoDelType({
              typeId: row.ID,
            });
            if (res.code === 200 && res.msg == "success") {
              this.$message({
                type: "success",
                message: `渠道类别删除成功`,
              });
              // 更新信息
              this.getChnnelTypeData();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 渠道类别操作弹窗
    showPrompt(type, row) {
      console.log("row: type", row, type);
      this.$prompt(`${type}渠道类别`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: /\S/,
        inputValue: `${row ? row.name : ""}`,
        inputErrorMessage: "渠道名不正确",
      })
          .then(async ({value}) => {
            let res = "";
            if (type == "修改") {
              res = await qudaoEditType({
                ID: row.ID,
                name: value,
              });
            } else {
              res = await qudaoCreateQudaotype({
                name: value,
              });
            }
            // 更新信息
            this.getChnnelTypeData();

            if (res.data && res.msg == "success") {
              this.$message({
                type: "success",
                message: `渠道类别${type}成功: ` + value,
              });
            } else {
              this.$message({
                type: "error",
                message: `渠道类别${type}失败: ` + value,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消",
            });
          });
    },

    // 确定时间
    confirmTime(event) {
      this.ticketForm.startTime = this.formTime(event[0]);
      this.ticketForm.endTime = this.formTime(event[1]);
    },

    // 格式化时间
    formTime(time) {
      let date = new Date(time);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },

    // 获取票种列表
    async getTickList() {
      this.ticketList = [];
      let res = await ticketList();
      if (res.data && res.msg == "success") {
        this.ticketList = res.data.filter(v=>!!v.qudaoID);
        this.ticketListOld = res.data;
        this.formTicketData();
      }
    },

    // 获取票种区域
    async getTicketAreaDate() {
      let res = await getTicketArea("0001-0001-0001-0001-0001-0001");
      console.log("res: ", res);
      if (res.data && res.msg == "success") {
        this.ticketAreaList = res.data.区域;
      }
    },

    // 添加票种按钮
    ChannelAddTicket(row) {
      this.ticketForm = {
        qudaoName: "",
        type: "单票",
        name: "成人票",
        qudaoID: "",
        price: "",
        sysCode: "",
        time: "",
        endTime: "",
        startTime: "",
        areaName: "",
        seat_number: 1,
      };
      console.log("ChannelAddTicket-----row: ", row);
      this.isCreateTicket = true;
      this.operationTicket = "新增";
      this.ticketForm.qudaoName = row.name;
      // 传渠道id
      this.ticketForm.qudaoID = row.ID;

      // this.getTicketAreaDate()
    },
    // 修改票种
    async changeTicketType(row) {
      console.log("row: ", row);
      this.isCreateTicket = true;
      this.operationTicket = "修改";
      let qudaoName = this.channelTypeList.filter(
          (item) => item.ID === row.qudaoID
      );
      this.ticketForm.ID = row.ID
      this.ticketForm.qudaoName = qudaoName[0].name;
      this.ticketForm.qudaoID = qudaoName[0].ID;
      this.ticketForm.name = row.name;
      this.ticketForm.price = row.price;
      this.ticketForm.areaName = row.areaName;
      this.ticketForm.sysCode = row.sysCode;
      this.ticketForm.seat_number = row.seat_number;
      // this.ticketForm.time = row.time
    },
    // 添加票种
    async addTicket(formName) {
      delete this.ticketForm.time;
      console.log("this.ticketForm: ", this.ticketForm);
      let that = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = "";
          if (that.operationTicket == "修改") {
            res = await editTicket(that.ticketForm);
          } else {
            res = await createTicket(that.ticketForm);
          }
          console.log("addTicket--res: ", res);
          if (res.data && res.msg == "success") {
            that.$message({
              type: "success",
              message: `票种${that.operationTicket}成功`,
            });
            that.isCreateTicket = false;
          } else {
            that.$message({
              type: "error",
              message: res.message[0],
            });
          }
          that.resetForm(formName);
          // 更新信息
          that.getTickList();
        }
      });
    },
    // 删除票种
    async deticketType(row) {
      console.log("row: ", row);
      this.$confirm("此操作将永久删除该票种, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            let res = await delTicket({typeId: row.ID});
            if (res.code === 200 && res.msg == "success") {
              this.$message({
                type: "success",
                message: `票种删除成功`,
              });
              // 更新信息
              this.getTickList();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    // 格式化票种数据
    formTicketData() {
      this.ticketList.map((item) => {
        item.time = `${this.formTime(item.startTime)}至${this.formTime(
            item.endTime
        )}`;
      });
    },

    // 验证规则
  },
  filters: {
    foramtQudao(id) {
      if (that.channelTypeList && that.channelTypeList.length) {
        let qudaoName = that.channelTypeList.filter((item) => item.ID === id);
        return qudaoName[0]?.name||'';
      }
    },
  },
};
</script>


<style scoped>
.box {
  height: 100vh;
  overflow-y: auto;
  /* border: 1px solid red; */
}

.add-channel {
  width: 60%;
  padding: 20px;
  /* border: 1px solid red; */
  background-color: #fff;
  border-radius: 20px;
  margin: 0 auto;
}

.channel-type {
  width: 60%;
  padding: 20px;
  /* border: 1px solid red; */
  background-color: #fff;
  border-radius: 20px;
  margin: 0 auto;
}
</style>
